@font-face {
  font-family: Manrope;
  src: url("/public/fonts/Manrope/Manrope-Regular.woff");
}
@font-face {
  font-family: ProductSans;
  src: url("/public/fonts/ProductSans/Product\ Sans\ Regular.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend&family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Playfair+Display:wght@400;700&family=Merriweather:wght@400;700&family=Poppins:wght@400;700&family=Lora:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*------Color variables------*/
    --black_900: #000000;
    --blue_100: #bddfff;
    --blue_gray_100: #d8d8d8;
    --blue_gray_400: #868686;
    --blue_gray_900: #001d47;
    --blue_gray_900_75: #001d4775;
    --cyan_a400: #00def7;
    --gray_50: #fafafa;
    --gray_700_0c: #6161610c;
    --light_blue_a700: #0084f7;
    --light_blue_a700_19: #0084f719;
    --white_a700: #ffffff;
    --yellow_600: #ffd829;

    /*------Shadow variables------*/
    --shadow-xs: 0 7px 15px 0 #6161610c;
    --shadow-sm: 0 0 3px 0 #bddfff;

    /*------Border radius variables------*/
    --radius-xs: 5px;
    --radius-sm: 10px;
    --radius-md: 12px;
    --radius-lg: 14px;
    --radius-xl: 16px;

    /*------Spacing variables------*/
    --space-xs: 2px;
    --space-sm: 4px;
    --space-md: 6px;
    --space-lg: 10px;
    --space-xl: 12px;
    --space-2xl: 14px;
    --space-3xl: 16px;
    --space-4xl: 18px;
    --space-5xl: 20px;
    --space-6xl: 24px;
  }
}

@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1380px];
  }
}

#root {
  display: flex;
  justify-content: center;
  -webkit-font-smoothing: subpixel-antialiased;
}
.promo-reg-cards {
  @apply rounded-[30px] h-[141px] text-center flex justify-center items-center xl:text-xl text-base font-semibold relative text-white xl:w-[352px] w-full xl:p-0 p-6;
}
.promo-reg-hooks {
  @apply flex xl:w-[75px] xl:h-[75px] w-[48px] h-[48px] left-4 xl:-top-14 -top-6 aspect-square absolute p-[15px] justify-center items-center;
  border-radius: 500px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05),
    0px 5px 5px 0px rgba(0, 0, 0, 0.04), 0px 10px 6px 0px rgba(0, 0, 0, 0.03),
    0px 18px 7px 0px rgba(0, 0, 0, 0.01), 0px 28px 8px 0px rgba(0, 0, 0, 0);
}
.promodownarrow {
  @apply xl:[transform:rotate(46.709deg)] xl:[width:100px!important] xl:[bottom:-230px] xl:[left:-80px]  left-[70%] -bottom-[90%] [transform:rotate(30deg)];
}

.promo-reg-spotlight {
  @apply xl:[width:460px] xl:[height:168px] w-full p-6 xl:p-0 xl:!text-xl !text-base;
  background-image: url("/public/images/über mich.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white, #fff);
  padding: 30px;
  background-color: hsl(0, 0%, 100%);
  background-blend-mode: hard-light;
  box-shadow: 0px 0px 2.76px 0px #bbd3ec, 0px 0px 5.52px 0px #bbd3ec,
    0px 0px 19.32px 0px #bbd3ec, 0px 0px 38.64px 0px #bbd3ec,
    0px 0px 66.24px 0px #bbd3ec, 0px 0px 115.92px 0px #bbd3ec;
}
.promo-reg-cards-1 {
  background: linear-gradient(85deg, #10376f 4.1%, #0084f7 292.95%);
}
.promo-reg-cards-2 {
  background: linear-gradient(269deg, #0084f7 -103.27%, #10376f 203.15%);
}
.promo-reg-cards-3 {
  background: linear-gradient(268deg, #0084f7 1.38%, #10376f 305.93%);
}
* {
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: subpixel-antialiased;
}
.ant-btn-primary {
  color: #fff;
  background-color: #001d47 !important;
}
.font-lexend * {
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: Lexend, cursive;
}
.font-roboto-slab * {
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: Roboto Slab, sans-serif;
}
.hero-text {
  background: linear-gradient(143deg, #00def7 0%, #001d47 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-page-hero {
  background: var(--kacheln-bg, #fafafa);
}

.hero-list li::before {
  content: "img";
  color: transparent;
  background: url("/public/images/HeartBullet.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-size: 25px;
  margin-right: 5px;
}
.numbers {
  border-radius: 50%;
  height: 64px;
  width: 64px;
  text-align: center;
  font-weight: 700;

  background: linear-gradient(134deg, #0084f7 0%, #001d47 100%);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.07),
    1px 1px 3px 0px rgba(0, 0, 0, 0.07), 4px 5px 6px 0px rgba(0, 0, 0, 0.06),
    10px 10px 8px 0px rgba(0, 0, 0, 0.04),
    17px 18px 10px 0px rgba(0, 0, 0, 0.01), 27px 28px 11px 0px rgba(0, 0, 0, 0);
}
@media (max-width: 1370px) {
  .numbers {
    width: 40px;
    height: 40px;
    font-size: 26px !important;
  }
}
.rounded-bg-blue {
  border-radius: 30px;
  background: linear-gradient(137deg, #001d47 0%, #1d4d92 100%);
  height: 100%;
  position: absolute;
}
.rounded-bg-blue-second {
  height: 100%;
  position: absolute;
  border-radius: 30px;
  background: url("/public/images/howitworkssecond.png");
  background-repeat: no-repeat;
  background-size: cover;
}
::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: #0084f746;
  border-radius: 40px;

  height: 10px;
  width: 10px;
}

.landing-page-arrow-btn-drop {
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))
    drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.05))
    drop-shadow(6px 9px 10px rgba(0, 0, 0, 0.04))
    drop-shadow(13px 20px 14px rgba(0, 0, 0, 0.03))
    drop-shadow(23px 35px 17px rgba(0, 0, 0, 0.01))
    drop-shadow(35px 55px 18px rgba(0, 0, 0, 0));
}
.people-to-people-card {
  border-radius: 30px;
  background: linear-gradient(53deg, #00def7 0%, #001d47 100%);
}

.people-people-shadow {
  --tw-shadow: 407px 458px 172px rgba(0, 0, 0, 0),
    260px 293px 157px rgba(0, 0, 0, 0.01), 146px 165px 132px rgba(0, 0, 0, 0.03),
    65px 73px 98px rgba(0, 0, 0, 0.04), 16px 18px 54px rgba(0, 0, 0, 0.05),
    0px 0px 0px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 407px 458px 172px var(--tw-shadow-color),
    260px 293px 157px var(--tw-shadow-color),
    146px 165px 132px var(--tw-shadow-color),
    65px 73px 98px var(--tw-shadow-color), 16px 18px 54px var(--tw-shadow-color),
    0px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.white-overlay {
  background: linear-gradient(
    90deg,
    #ffffff -165%,
    rgba(192, 192, 192, 0) 366%
  );
  height: 115px;
  position: relative;
  top: -112px;
  border-radius: 30px;
  width: 100%;
  pointer-events: none;
}
.people-to-people-card-second {
  border-radius: 30px;
  background: linear-gradient(
    53deg,
    #001d47 0%,
    #00def7 43.75%,
    #0084f7 98.96%
  );
}
.makeyoufit-card {
  border-radius: 30px;
  background: linear-gradient(53deg, #001d47 0%, #0084f7 98.96%);
}
.makeyoufit-second-card {
  border-radius: 1.875rem;
  background: linear-gradient(173deg, #0084f7 0%, #001d47 100%);
}
.grub-dich-card {
  background: url("/public/images/über mich.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.gray-bg-gradient {
  background: rgb(250, 250, 250);
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 1) 90%,
    rgba(255, 255, 255, 1) 90%
  );
}
.btn-div {
  position: relative;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 84px solid #fafafa;
  border-radius: 0 0 20px 20px;
  width: 100%;
  overflow: hidden;
}

.btn-div::after {
  content: " ";
  position: absolute;
  height: 20px;
  width: 22px;
  rotate: 15deg;
  right: 33.5%;
  top: -5px;
  border-radius: 50%;
  background-color: #fafafa;
  background: radial-gradient(at right bottom, transparent 60%, #fafafa 60%);
}
.btn-div::before {
  content: " ";
  position: absolute;
  height: 20px;
  width: 12px;
  rotate: 208deg;
  right: 39.95%;
  top: 65px;
  box-shadow: -10px -3px 0px 2px white;
  border-radius: 50%;
  background-color: #fafafa;
  background: radial-gradient(at right bottom, transparent 60%, #fafafa 60%);
}

.specailities-list li::before {
  content: "img";
  color: transparent;
  background: url("/public/images/morflax-studio-11.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(90deg);
  font-size: 26px;
  margin-right: 5px;
}
.specailities-list-second li::before {
  content: "img";
  color: transparent;
  background: url("/public/images/morflaxstudio55@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(90deg);
  font-size: 26px;
  margin-right: 5px;
  text-align: center;
}
.gray-list-second li::before {
  content: "img";
  color: transparent;
  background: url("/public/images/vector--1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  font-size: 20px;
  margin-right: 5px;
  text-align: center;
}
.bg-blue-linear-gradient {
  border-radius: 1.875rem;
  background: linear-gradient(137deg, #001d47 0%, #005ff8 100%);
}
.ant-menu-item-selected {
  background-color: #0084f7 !important;
  color: white !important;
}
.ant-menu-item:hover {
  background-color: #0084f7 !important;
  color: white !important;
}
.bg-glass-circles {
  background: url("/public/images/glass-circles.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-glass-strings {
  background: url("/public/images/Group 3338.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.black-search .ant-input::placeholder {
  color: #000;
}

.ant-segmented-item-selected {
  background: #0084f7 !important;
  color: white !important;
}

@media screen and (min-width: 1370px) {
  .so-funktioniert-eingutertipp {
    top: 65px;
    position: relative;
  }
}
.red-mark {
  position: relative;
}
.red-mark::before {
  content: "↓";
  color: #e81900;
  font-weight: bold;
  position: absolute;
  left: -20px;
  top: 10px;
  font-size: 15px;
  text-align: start;
}
.red-mark-upper {
  position: relative;
  width: fit-content;
  margin: auto;
}
.red-mark-upper::before {
  content: "↓";
  color: #e81900;
  font-weight: bold;
  position: absolute;
  left: -20px;
  top: 1px;
  font-size: 15px;
  text-align: start;
}
.green-mark {
  position: relative;
}
.green-mark::before {
  content: "↑";
  color: #40c945;
  font-weight: bold;
  position: absolute;
  left: -20px;
  top: 10px;
  font-size: 15px;
  text-align: start;
}
.green-mark-upper {
  position: relative;
  width: fit-content;
  margin: auto;
}
.green-mark-upper::before {
  content: "↑";
  color: #40c945;
  font-weight: bold;
  position: absolute;
  left: -20px;
  top: 1px;
  font-size: 15px;
  text-align: start;
}
.rounded-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;

  background: conic-gradient(
    from 180deg at 50% 50%,
    #4f8fe6 0deg,
    #5e5e5e 91.88deg,
    #40c945 178.12deg,
    #f52b2b 286.87deg,
    #e6b63d 360deg
  );
}
.glass-circles-red-bg {
  background: url("/public/images/glass-circles-copy-41728-x-10051.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.rounded-header {
  border-radius: 150px 30px 0px 0;
}
.white-text-div-folder h5 {
  font-weight: 600;
  font-size: 16px;
}
.white-text-div-folder-folded h5 {
  font-weight: 600;
  font-size: 16px;
}
.white-text-div-folder p {
  color: var(--text-sec, #868686);

  font-size: 14px;

  font-weight: 500;
}
.white-text-div-folder-folded p {
  color: var(--text-sec, #ffffff);

  font-size: 14px;

  font-weight: 500;
}
.trapezoid {
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;

  width: 125px;
  border-radius: 15px 0 0 0;
}
.blueswitch .ant-switch-inner {
  background: rgb(175, 175, 175) !important;
}
.greenswitch .ant-switch-inner {
  background: rgb(175, 175, 175) !important;
}

.blueswitch .ant-switch-checked .ant-switch-inner {
  background: #0084f7 !important;
}
.greenswitch .ant-switch-checked .ant-switch-inner {
  background: #40c945 !important;
}
.trkisswitch .ant-switch-checked .ant-switch-inner {
  background: #00def7 !important;
}
.float-label {
  position: relative;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  color: #868686;
  pointer-events: none;
  left: 12px;
  top: 8px;
  transition: 0.2s ease all;
}

.label-float {
  top: -10px;
  padding: 0px 3px;
  background-color: white;
  font-size: 10px;
}
.leader-board-hero {
  background: url("/public/images/mesh-gradient.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  position: relative;
}
.leader-board-hero-second {
  background: url("/public/images/herobg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  position: relative;
}

.ant-table-cell {
  background: transparent !important;
  border: none !important;
}
.ant-table-thead .ant-table-cell {
  color: var(--text-sec, #868686) !important;
}

.superbg {
  background: linear-gradient(128deg, #fda69f 0%, #e3725f 39.06%, #d05835 100%);
}
.png-folder {
  clip-path: polygon(0 100%, 0 11%, 62% 10%, 62% 0, 100% 0, 100% 100%);
}
.bg-svg {
  background-image: url("/public/images/Rectangle\ 12.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.text-whitner:hover p,
.text-whitner:hover span {
  color: white !important;
}
.text-whitner-second p,
.text-whitner-second span {
  color: white !important;
}
.bg-blue-hearts {
  background-image: url("/public/images/Frame 21.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.innerWhite {
  box-shadow: 0px -35px 60px 16px #ffffff inset;
}
.image-overlay-eye {
  display: none;
}
.image-overlay-eye-container:hover > img:nth-child(2) {
  display: block;
}
.cover-upload-bg {
  background: linear-gradient(180deg, #0084f7 0%, #001d47 100%);
  background-position: center;
  clip-path: polygon(100% 3%, 0% 100%, 100% 100%);
}
.cover-upload-bg-second {
  background: linear-gradient(180deg, #0084f7 0%, #001d47 100%);
  background-position: center;
  clip-path: polygon(100% 2%, 0% 100%, 100% 100%);
}
.circle {
  clip-path: circle(50% at 50% 50%);
}
.ReactCollapse--collapse {
  transition: height 500ms;
}
.hidechild * {
  display: hidden;
}
@media screen and (max-width: 1280px) {
  .hidechild * {
    display: block;
  }
}

.increaseDuration {
  transition-duration: 500ms !important;
}
.shadow-custom {
  box-shadow: -3px -10px 5px -4px rgba(130, 130, 130, 0.75);
  -webkit-box-shadow: -3px -10px 5px -4px rgba(130, 130, 130, 0.75);
  -moz-box-shadow: -3px -10px 5px -4px rgba(130, 130, 130, 0.75);
  transform: translateY(15px);
}

.no-arr-select select {
  padding: 0px 5px;
  font-weight: 600;
  font-size: 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* For IE10 */
.no-arr-select select::-ms-expand {
  display: none;
}
.ant-segmented-thumb {
  background-color: #0084f7 !important;
  color: white !important;
}
.video-card-one {
  background-image: url("/public/images/tut1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.video-card-two {
  background-image: url("/public/images/tut2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.video-card-three {
  background-image: url("/public/images/tut3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ql-container.ql-snow {
  border-radius: 0px 0px 15px 15px;
  border: 1.5px solid var(--grey-accent, #d8d8d8) !important;
  height: 150px;
}
.ql-toolbar.ql-snow {
  border-radius: 15px 15px 0px 0px;

  border: 1.5px solid var(--grey-accent, #d8d8d8) !important;
  border-bottom: none;
  padding: 1rem 0rem 10px 10px !important;
}

.support-table table {
  background-color: #fafafa;
  border-radius: 30px;
  padding: 10px;
}
.react-confirm-alert {
  position: relative;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 5000;
}
.grad-one-bg {
  background: url("/public/images/gradient01.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.grad-two-bg {
  background: url("/public/images/gradient02.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  outline: none !important;
}
.grad-two-bg:hover {
  border: 1px solid #0084f7 !important;
  color: white !important;
}
.react-select {
  font-size: 16px !important;
  min-width: 200px;
}
.react-select input::placeholder {
  color: black !important;
}
.mod.react-select input::placeholder {
  color: #868686 !important;
  font-weight: 400 !important;
}
.super-select__control {
  border-radius: 10px !important;
  padding: 0px 15px !important;
}
.super-select__placeholder {
  color: #868686 !important;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
}
.card-table .ant-table-cell {
  padding: 3px !important;
}
.card-table th {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.card-table .ant-empty {
  margin-block: 0px !important;
}
.select-campaign div {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}
.special-upload .ant-upload-list-item-name {
  display: none !important;
}
.special-upload .ant-upload-icon {
  display: none !important;
}
.special-upload .ant-upload-list-item-actions {
  display: none !important;
}

.black-search-input .ant-input::placeholder {
  color: #000;
}
.black-search-input .ant-input-group-addon {
  display: none;
}
.black-search-input .ant-input-group-wrapper {
  border-radius: 8px;
  overflow: hidden;
}
.black-search-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-content {
  /* padding: 2rem !important; */
  outline: 1rem solid #001d47 !important;
  border-radius: 0.5rem !important;
}
.standardRoot-modal > div > div > .ant-modal-content {
  outline: unset !important;
}
.standardRoot-modal > div > div > div > .ant-modal-close {
  background: transparent !important;
}

.ant-modal-close {
  position: absolute !important;
  background-color: white !important;
  top: -30px !important;
  right: -30px !important;
  scale: 1.4;
}
.ant-modal-content input::placeholder {
  color: #868686 !important;
  font-weight: 400 !important;
}
.ant-dropdown {
  z-index: 6000 !important;
}
.profile-dropdown-custom li:hover {
  background-color: #0084f7 !important;
  color: white !important;
}
.profile-dropdown-custom li {
  color: black !important;
  padding: 12px 20px !important;
  width: 200px;
  height: 50px;
  position: relative;
}
.profile-dropdown-custom li svg {
  width: 30px !important;
  left: 0 !important;
  position: static !important;
}
.profile-dropdown-custom li p {
  width: 100% !important;
  text-align: start !important;
}
.profile-dropdown-custom-menu {
  padding: 0 !important;
}

.profile-dropdown-custom li:last-child {
  border-top: 1px solid #d8d8d8;
  border-radius: 0px !important;
}
.profile-dropdown-custom {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  overflow: hidden;
}
.profile-select__control {
  @apply !shadow-none !border-none;
}
.profile-select {
  @apply font-semibold text-xl;
}
.super-special-div {
  background: rgba(248, 248, 248, 0.4);
  background: linear-gradient(
    121deg,
    rgba(248, 248, 248, 0.3) 0%,
    rgba(0, 255, 0, 0) 80%
  );
  background: -webkit-linear-gradient(
    121deg,
    rgba(248, 248, 248, 0.3) 0%,
    rgba(0, 255, 0, 0) 80%
  );
  background: -moz-linear-gradient(
    121deg,
    rgba(248, 248, 248, 0.3) 0%,
    rgba(0, 255, 0, 0) 80%
  );
}
.ant-upload-list {
  display: none !important;
}
.yellow-card-box-shadow {
  border-radius: 20px;
  border: 1.5px solid var(--grey-accent, #d8d8d8);
  background: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05),
    4px 6px 17px 0px rgba(0, 0, 0, 0.05), 17px 25px 31px 0px rgba(0, 0, 0, 0.04),
    39px 57px 41px 0px rgba(0, 0, 0, 0.03),
    70px 101px 49px 0px rgba(0, 0, 0, 0.01),
    109px 158px 54px 0px rgba(0, 0, 0, 0);
}
.search-select-dashboard-selection-placeholder {
  color: black !important;
  font-weight: 500;
}
.search-select-dashboard-selector {
  border: none !important;
  box-shadow: none !important;
}
.sm-selection-placeholder {
  color: black !important;
  font-weight: 500;
}
.sm-selector {
  border: none !important;
  box-shadow: none !important;
}
.sm-selector:focus {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid black;
}
.sm-selection-search-input {
  border-bottom: 1px solid #868686 !important;
}
.sm-selection-search-input:focus {
  border-bottom: 1px solid #000 !important;
}
@media (max-width: 500px) {
  :where(.css-dev-only-do-not-override-1hoiyns).ant-segmented
    .ant-segmented-item-label {
    min-height: 0;
    line-height: 20px;
    padding: 0 5px;
  }
}
@media (max-width: 500px) {
  ::-webkit-scrollbar {
    height: 5px;
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #0084f746;
    border-radius: 40px;

    height: 5px;
    width: 5px;
  }
}
#root {
  overflow: hidden;
}
.antd-toast-custom {
  z-index: 8000 !important;
}
textarea {
  overflow: auto !important;
}
.ant-custom-tooltip {
  z-index: 7000 !important;
}
.promoterregselect div,
.promoterregselect span:not(.promoterregselect-arrow),
.promoterregselect input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  text-align: start !important;
  font-size: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 600 !important;
  background-color: transparent !important;
}

.pdf_name_typography div,
.pdf_name_typography {
  margin: 0 auto !important;
  height: auto !important;
  padding: 0 !important;
  color: white !important;
}
.pdf_name_typography .anticon.anticon-edit {
  color: #005ff8 !important;
  margin: 0 1rem !important;
}
.unreadNewIcon {
  shape-outside: circle(50%);
  clip-path: url("/public/notificationicons/icons-8-neu-34.svg");
}
.super-line {
  top: -5px;
  right: 0;
  width: 396px;
  border-radius: 250px;
  height: 5px;
  z-index: 10;
  position: absolute;
}
.specialeditor {
  display: none;
}
.desktopspecialeditor {
  scale: 1.1;
  display: block;
  right: 346px;
  content: " ";
  top: -85px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100;
  rotate: -1deg;
}
@media (max-width: 768px) {
  .super-line {
    width: 223px;
    height: 10px;

    border-radius: 250px;
  }
  .desktopspecialeditor {
    display: none;
  }
  .border-editor:before {
    display: none;
  }
  .specialeditor {
    display: block;
    right: 222px;
    content: " ";

    top: -65px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    position: absolute;
    z-index: 100;
    rotate: 0deg;
  }

  .super-special-div {
    background: rgba(248, 248, 248, 0.4);
    background: linear-gradient(
      330deg,
      rgba(248, 248, 248, 0.3) 0%,
      rgba(0, 255, 0, 0) 80%
    ) !important;
    background: -webkit-linear-gradient(
      330deg,
      rgba(248, 248, 248, 0.3) 0%,
      rgba(0, 255, 0, 0) 80%
    ) !important;
    background: -moz-linear-gradient(
      330deg,
      rgba(248, 248, 248, 0.3) 0%,
      rgba(0, 255, 0, 0) 80%
    ) !important;
  }
}

@media (max-width: 950px) {
  .leaderboardtable .leaderboardtable-cell {
    padding: 5px !important;
    font-size: 16px !important;
  }
  .leaderboardtable th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  /* .leaderboardtable thead {
    display: none !important;
  } */
  .leaderboardtable td,
  th {
    min-width: none;
    max-width: 100px;
  }
}

@media (max-width: 400px) {
  .ant-modal {
    width: 80% !important;
  }
  .standardRoot-modal > .ant-modal {
    width: 95% !important;
  }
  .standardRoot-modal {
    overflow-x: hidden !important;
  }
}

/* .ant-menu-inline-collapsed-tooltip {
  display: none;
} */
@media (max-width: 365px) {
  .features div {
    position: static !important;
    width: fit-content;
    margin: auto;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .features {
    overflow: auto;
  }
  .features > div {
    margin: 5px auto;
  }
}
table {
  overflow: auto;
}
.videoplayer div {
  width: 100% !important;
}
.wordbreak {
  word-break: break-all !important;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.gradient-picker-modal {
  min-width: 300px;
}

.gradient-picker-modal button {
  margin-top: 10px;
}

.custom-spacing {
  margin-bottom: clamp(1rem, 0.933rem + 0.33vw, 1.2rem) !important;
}
.ql-editor p.custom-spacing {
  margin-bottom: clamp(1rem, 0.933rem + 0.33vw, 1.2rem);
}

.gradient-text {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 0 2px;
}

/* Mobile sizes */
.ql-snow .ql-size-12px,
.ql-size-12px {
  font-size: 0.875rem; /* 14px */
}

.ql-snow .ql-size-16px,
.ql-size-16px {
  font-size: 1rem; /* 16px */
}

.ql-snow .ql-size-36px,
.ql-size-36px {
  font-size: 1.07rem; /* 18px */
}

.ql-snow .ql-size-20px,
.ql-size-20px {
  font-size: 1.75rem; /* 28px */
}

.ql-snow .ql-size-24px,
.ql-size-24px {
  font-size: 2rem; /* 32px */
}

/* Desktop sizes */
@media (min-width: 768px) {
  .ql-snow .ql-size-12px,
  .ql-size-12px {
    font-size: 0.875rem; /* 14px */
  }

  .ql-snow .ql-size-16px,
  .ql-size-16px {
    font-size: 1rem; /* 16px */
  }

  .ql-snow .ql-size-36px,
  .ql-size-36px {
    font-size: 1.25rem; /* 20px */
  }

  .ql-snow .ql-size-20px,
  .ql-size-20px {
    font-size: 2rem; /* 32px */
  }

  .ql-snow .ql-size-24px,
  .ql-size-24px {
    font-size: 2.5rem; /* 40px */
  }
}

.ql-container {
  max-width: 100%;
  overflow-x: auto;
}

.ql-editor {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
}

.ql-editor p {
  max-width: 100%;
  white-space: pre-wrap;
}

/* Für Gradient-Boxen */
.gradient-wrapper {
  max-width: 100%;
  box-sizing: border-box;
}

/* Für den Toolbar */
.ql-toolbar {
  max-width: 100%;
  flex-wrap: wrap;
}

/* Picker Labels */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "Text-Small";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "Text-Medium";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before {
  content: "Text-Large";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "Überschrift-Small";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
  content: "Überschrift-Medium";
  font-size: xx-small;
}

/* Picker Items */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "Text-Small";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Text-Medium";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "Text-Large";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "Überschrift-Small";
  font-size: xx-small;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "Überschrift-Medium";
  font-size: xx-small;
}
.ql-align-right img {
  display: block;
  margin-left: auto;
  margin-right: 0;
}
.ql-gradient {
  display: inline-block;
  background: none;
  font-size: 12px;
  line-height: 0;
}

.ql-editor span[style*="background"] {
  padding: 0px 5px;
  border-radius: 15px;
  display: inline-block;
}

.gradient-container {
  max-width: fit-content;
}

.ql-gradient::before {
  content: "🌀";
}

.ql-removeGradient::before {
  content: "🌀";
  text-decoration-line: line-through;
  text-decoration-thickness: 3px;
}

.ql-clear::before {
  content: "🗑️";
  font-size: 12px;
}

@media (max-width: 768px) {
  .ql-clear {
    position: absolute;
    margin-left: 0.5rem;
    margin-top: -0.65rem;
  }
}

.ql-clear {
  display: inline-block;
  background: none;
  font-size: 12px;
  line-height: 0;
}

.ql-removeGradient {
  display: inline-block;
  background: none;
  font-size: 12px;
  line-height: 0;
}

.gradient-text-break {
  display: inline-block;
  vertical-align: top;
}

@supports (-webkit-box-decoration-break: clone) or (box-decoration-break: clone) {
  .gradient-text-break + .gradient-text-break {
    margin-top: 0.25em; /* Abstand nach oben zum vorherigen Element */
  }

  /* Optional: Wenn Sie auch unten einen Abstand möchten */
  .gradient-text-break {
    margin-bottom: 0.25em;
  }
}

.ql-align-left {
  text-align: left;
}
@media (max-width: 768px) {
  .ql-align-center {
    width: 100% !important;
  }
  .ql-align-left {
    width: 100% !important;
  }
  .ql-align-right {
    width: 100% !important;
  }
}
.ql-align-left img {
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.ql-align-center {
  text-align: center;
}

.ql-align-center img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ql-indent-1 {
  margin-left: 45px;
  list-style-type: circle;
}

.ql-indent-2 {
  margin-left: 60px;
  list-style-type: circle;
}

.ql-indent-3 {
  margin-left: 75px;
  list-style-type: circle;
}

.shine {
  filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.8));
}

.clip-triangle {
  clip-path: polygon(52% 100%, 0 0, 100% 0);
}

.ant-layout {
  z-index: 0;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.ant-menu-item {
  text-overflow: unset !important;
}

.text-none {
  text-align: center;
}

@media (min-width: 1370px) {
  .text-none {
    text-align: unset;
  }
}

.custom-spin .ant-spin-dot-item {
  background-color: #ff5733 !important;
}

.imageProperty {
  object-position: 50% 25%;
}

@media (max-width: 1337px) {
  .text-whiteImportant * {
    color: white !important;
  }
}

.ql-container {
  resize: vertical;
  overflow: auto;
}

.override-font,
.override-font * {
  font-family: var(--font-family) !important;
}
.ant-rate-star-first,
.ant-rate-star-second {
  display: flex;
  align-items: flex-start; /* Nach oben ausrichten */
}

.DraftEditor-root .public-DraftStyleDefault-block {
  margin: 0;
  padding: 0;
}
